const OPTION_SELECTED_CLASS = 'outline-black'
const OPTION_SELECTED_CLASS_DARK = '[.is-dark-mode_&]:outline-white'
const OPTION_SELECTED_CLASS_BORDER = 'product-card-selected'
const OPTION_COLOR_SELECTOR = '.js-product-color'
const PRODUCT_CARD_SELECTOR = '.js-product-card'
const PRODUCT_CARD_HOVER_SELECTOR = '.js-product-card-hover'
const PRODUCT_CARD_IMAGE_SELECTOR = '.js-product-card-image'
const YOTPO_DESKTOP_SELECTOR = '.js-review-desktop'
const YOTPO_MOBILE_SELECTOR = '.js-review-mobile'
const WISH_LIST_BTN_SELECTOR = '.js-add-to-wishlist'
const WISH_LISTED_CLASS = 'is-wish-listed'
const IN_VIEWPORT_CLASS = 'product-card--in-viewport'

class ProductCard extends HTMLElement {
  constructor () {
    super()

    const productCardEl = this.querySelector(PRODUCT_CARD_SELECTOR)
    const optionColorEls = productCardEl?.querySelectorAll(OPTION_COLOR_SELECTOR)
    const productCardHoverEl = this.querySelector(PRODUCT_CARD_HOVER_SELECTOR)
    const optionColorHoverEls = productCardHoverEl?.querySelectorAll(OPTION_COLOR_SELECTOR)
    const wishListButtonEl = this.querySelector(WISH_LIST_BTN_SELECTOR)
    const yotpoDesktopEl = this.querySelector(YOTPO_DESKTOP_SELECTOR)
    const yotpoMobileEl = this.querySelector(YOTPO_MOBILE_SELECTOR)

    wishListButtonEl && wishListButtonEl.addEventListener('click', this.toggleWishList.bind(this))
    optionColorEls && productCardEl && this.selectColor(productCardEl, optionColorEls)
    productCardHoverEl && optionColorHoverEls && this.selectColor(productCardHoverEl, optionColorHoverEls)

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.classList.add(IN_VIEWPORT_CLASS)
        observer.disconnect()
      }
    }, {});
    observer.observe(this)


    if (yotpoDesktopEl) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const starsEl = yotpoDesktopEl.querySelector('.yotpo-bottomline a')
            if (starsEl) {
              this.editReviewMarkup(starsEl)
            }
            mutationObserver.disconnect()
          }
        }
      }

      const mutationObserver = new MutationObserver(callback)
      mutationObserver.observe(yotpoDesktopEl, { attributes: true, childList: true, subtree: true })
    }

    if (yotpoMobileEl) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const starsEl = yotpoMobileEl.querySelector('.yotpo-bottomline a')
            if (starsEl) {
              this.editReviewMarkup(starsEl)
            }
            mutationObserverMobile.disconnect()
          }
        }
      }

      const mutationObserverMobile = new MutationObserver(callback)
      mutationObserverMobile.observe(yotpoMobileEl, { attributes: true, childList: true, subtree: true })
    }
  }

  editReviewMarkup (starsEl) {
    const content = starsEl.innerHTML
    starsEl.innerHTML = content.split(' ')[0] || starsEl.innerHTML
  }

  selectColor (parent, options) {
    const _this = this
    options.forEach(element => {
      const activateColor = () => {
        const imageFeatured = element.dataset.featuredImage
        const productCardImageEl = parent.querySelector(PRODUCT_CARD_IMAGE_SELECTOR)
        productCardImageEl.setAttribute('src', imageFeatured)
        productCardImageEl.setAttribute('srcset', imageFeatured)
        _this.initOptionColor(options)
        element.classList.add(OPTION_SELECTED_CLASS, OPTION_SELECTED_CLASS_DARK, OPTION_SELECTED_CLASS_BORDER)
      }

      element.addEventListener('click', activateColor)
      element.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          activateColor()
        }
      })
    })
  }

  initOptionColor (els) {
    els.forEach(el => {
      el.classList.remove(OPTION_SELECTED_CLASS, OPTION_SELECTED_CLASS_DARK, OPTION_SELECTED_CLASS_BORDER)
    })
  }

  toggleWishList () {
    this.classList.toggle(WISH_LISTED_CLASS)
  }
}
customElements.define('product-card', ProductCard)
